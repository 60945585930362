import React from 'react';

import I18n from '~/src/common/services/I18n';
import { Cart } from '~/src/common/typings/cart';
import { getDeliveryDateWithTimeslotInterval } from '~/src/common/utils/date';
import { getSelectedAddressLabel, isTimeslotValid } from '~/src/common/utils/delivery';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';

import * as S from './layout';

export interface Props {
  hasAddress: boolean;
  timeSlot?: { from: number; to: number; orderUntil: number } | null;
  onDeliveryAddressClick: () => void;
  onDeliverySlotClick: () => void;
}

const DeliveryItems = ({
  hasAddress,
  timeSlot,
  onDeliveryAddressClick,
  onDeliverySlotClick,
}: Props) => {
  const { data: cart } = useGetCart<Cart>();

  const addressLabel = cart?.delivery ? getSelectedAddressLabel(cart?.delivery) : '';
  const hasTimeslot = timeSlot && isTimeslotValid(timeSlot);
  const slotLabel = hasTimeslot
    ? getDeliveryDateWithTimeslotInterval(timeSlot, false, false)
    : I18n.t('navigation.choose-slot');

  return (
    <S.DeliveryContainer>
      <S.DeliveryButtonItem
        onClick={onDeliveryAddressClick}
        variant="secondary"
        icon="marker-normal"
        iconSize={16}
        $isToComplete={!hasAddress}>
        {hasAddress ? addressLabel : I18n.t('navigation.no-address')}
      </S.DeliveryButtonItem>
      {hasAddress ? (
        <S.DeliveryButtonItem
          label={I18n.t('navigation.choose-slot-aria-label')}
          onClick={onDeliverySlotClick}
          variant="secondary"
          icon="calendar"
          iconSize={16}
          $isToComplete={!hasTimeslot}>
          {slotLabel}
        </S.DeliveryButtonItem>
      ) : null}
    </S.DeliveryContainer>
  );
};

export default DeliveryItems;
